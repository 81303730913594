import './style.css'
import * as THREE from 'three'

import * as dat from 'dat.gui'
import Stats from 'three/examples/jsm/libs/stats.module.js';
import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {
    DRACOLoader
} from 'three/examples/jsm/loaders/DRACOLoader.js';
import {
    RoomEnvironment
} from 'three/examples/jsm/environments/RoomEnvironment.js';
import {
    OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js';
import {
    Camera,
    Mesh
} from 'three';

import {
    RenderPass
} from 'three/examples/jsm/postprocessing/RenderPass.js';
import {
    EffectComposer
} from 'three/examples/jsm/postprocessing/EffectComposer.js';
import {
    OutlinePass
} from 'three/examples/jsm/postprocessing/OutlinePass.js';
import {
    ShaderPass
} from 'three/examples/jsm/postprocessing/ShaderPass.js';
import {
    FXAAShader
} from 'three/examples/jsm/shaders/FXAAShader.js';


// // Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.clearColor(0xffffff)
document.body.appendChild(renderer.domElement);

// Scene
const pmremGenerator = new THREE.PMREMGenerator(renderer);
const scene = new THREE.Scene()
scene.background = new THREE.Color(0xD4F1F4);
scene.environment = pmremGenerator.fromScene(new RoomEnvironment(), 1).texture;

const container = document.querySelector('#container')
const stats = new Stats();
container.appendChild( stats.dom );

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("js/libs/draco/gltf/")

const loader = new GLTFLoader();



var model;
var meshModel;



const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

let mixer;
let myAnimationClips;

const clock = new THREE.Clock();




var zoomToComputer = false;
var shelfZoomed = false;




window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(90, sizes.width / sizes.height, 0.5, 100)





/**
 * Animate
 */


const controls = new OrbitControls(camera, renderer.domElement);
controlsSetting();


// !OUTLINE, COMPOSER AND SHADERPASS INIT
const composer = new EffectComposer(renderer);

// Array of object to outlinePass
let selectedObjects = [];

const renderPass = new RenderPass(scene, camera);

const outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), scene, camera);
outlinePass.renderToScreen = true;

// Outline params for outline proper effect
var outLinePassParams = {
    edgeStrength: 2,
    edgeGlow: 1,
    edgeThickness: 1.0,
    pulsePeriod: 0,
    usePatternTexture: false
}
// Outline params setting on outlinepass
outlinePass.edgeStrength = outLinePassParams.edgeStrength;
outlinePass.edgeGlow = outLinePassParams.edgeGlow;
outlinePass.visibleEdgeColor.set(0xffffff);
outlinePass.hiddenEdgeColor.set(0xffffff);

composer.addPass(renderPass);
composer.addPass(outlinePass);

const effectFXAA = new ShaderPass(FXAAShader);
effectFXAA.uniforms['resolution'].value.set(1 / window.innerWidth, 1 / window.innerHeight);
composer.addPass(effectFXAA);

const initalCameraPosition = new THREE.Vector3(0, 17, 27)



// !DRACOLOADER INIT
loader.setDRACOLoader(dracoLoader);

// !MODEL INPORT WITH DATGUI SETTING
loader.load('./room.glb', function (gltf) {
    document.querySelector(".loadingScreen").style.display = "none";
    model = gltf.scene;
    model.scale.set(0.5, 0.5, 0.5);
    model.position.set(0, 0, 0);

    meshModel = model.children;

    console.log("\nModello 3D:")
    console.log(model);

    var tempString = "\nI mesh nel modello 3D:";
    meshModel.forEach(mesh => {
        tempString += mesh.name + " - "
    });
    console.log(tempString);
    console.log(meshModel)

    tempString = "\nAnimazioni nei vari mesh: \n";
    meshModel.forEach(mesh => {
        if (mesh.animations.length > 0) tempString += `${mesh.name}: ${mesh.animations} - `
    });
    console.log(tempString);

    console.log(gltf.animations)
    myAnimationClips = gltf.animations;


    scene.add(model);

    
    // camera.position.set(0, 17, 27)
    camera.position.copy(initalCameraPosition)

    scene.add(camera);

    makeGlass(meshModel);
    // addDatGui();

    bookAndAnimation = createMeshAnimationDictionary();

    controls.update();
    animate();
}, undefined, function (e) {
    console.error(e)
});




function addDatGui(){
    var gui = new dat.GUI();

    gui.add(camera.position, 'x', -115,115).step(0.01);
    gui.add(camera.position, 'y', -115,115).step(0.01);
    gui.add(camera.position, 'z', -115,115).step(0.01);
}



canvas.addEventListener("click", checkCollision);

function checkCollision(event) {
    pointer.set((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
    raycaster.setFromCamera(pointer, camera);
    const intersects = raycaster.intersectObjects(model.children, true);

    if (intersects.length > 0) {
        const selectedObject = intersects[0].object;
        console.log("collisone con " + intersects[0].object.name.split("_")[0]);
        if (selectedObject.name.includes("Libro")) showShelf(selectedObject);
        else if(!selectedObject.name.includes("PcMonitor")) startAnimation(findMesh(selectedObject));
    }
}

function findMesh(mesh){
    let meshTrovato;
    meshModel.forEach(meshInModel => {
        if(meshInModel.name.split("_")[0] === mesh.name.split("_")[0]) meshTrovato = meshInModel;
    });
    return meshTrovato;
}


function makeGlass(meshArray) {
    const material = new THREE.MeshPhysicalMaterial({
        color: 0xffffff,
        metalness: 0.2,
        roughness: 0.2,
        ior: 1.5,
        envMapIntensity: 1,
        transmission: 1, // use material.transmission for glass materials
        specularIntensity: 1,
        specularColor: 0xffffff,
        opacity: 1,
        transparent: true
    });

    meshArray.forEach(mesh => {
        if (mesh.name.split("_")[0].includes("Finestra")) {
           
            if(mesh.children.length > 0){
                console.log("ha figli")
                console.log(mesh)
                mesh.children[0].material = material;
            }
            else mesh.material = material;
            console.log(mesh.name)
        }
    });
}


const btnPortfolio = document.querySelector(".discoverBtn");
btnPortfolio.addEventListener("click", showPortfolio);


function showPortfolio() {
    // Getting the mesh to trigger animation
    let computerMesh = null;
    meshModel.forEach(mesh => {
        if (mesh["name"] == "PcMonitor") computerMesh = mesh;
    });
    zoomToComputer = true;
    resetCamera();
    stopControls();

    btnPortfolio.disabled = true;

    camera.lookAt(computerMesh.position);
    camera.position.set(computerMesh.position.x + 0.8, computerMesh.position.y - 4.8, computerMesh.position.z + 13);
    
    startAnimation(computerMesh);
    
    setTimeout(showMacView, 2500);
    

}



let bookAndAnimation;

function createMeshAnimationDictionary(){
    let meshAnimation = [];
    const booksAnimation = getBooksAnimation();
    const booksMesh = getBooksMesh();
    // CREATING THE DICTIONARI KEY: BOOK MESH   VALUE: [ANIMATION, MIXER]
    if(booksAnimation.length > 0 && booksMesh.length > 0){
        for(var i = 0; i < booksAnimation.length; i++){
            const mixer = new THREE.AnimationMixer(booksMesh[i]);
            const action = mixer.clipAction(booksAnimation[i]);
            meshAnimation.push({
                key: booksMesh[i],
                value: [action, mixer]
            });
        }
    }
    return meshAnimation;
}

function showShelf(bookSelected) {
    let shelfMesh = null;

    meshModel.forEach(mesh => {
        if (mesh.name == "ScaffaleSx") shelfMesh = mesh;
    });

    if (shelfMesh != null) {
        if(!shelfZoomed){
            shelfZoomed = true;
            resetCamera();
            stopControls();
            camera.lookAt(shelfMesh.position)
            camera.position.set(-6.7, 12.6, -5.8)
            
        }
        reverseAnimation(bookSelected);
        startBookAnimation(bookSelected);
    }
}
function startBookAnimation(mesh){
    let currentBookAnimation = null;
    bookAndAnimation.forEach(dictionaryElem => {
        if(dictionaryElem.key.name ==  mesh.name.split("_")[0]) currentBookAnimation = dictionaryElem.value[0];
    });
    console.log(currentBookAnimation)
    if(currentBookAnimation != null){
        currentBookAnimation.clampWhenFinished = true;
        currentBookAnimation.loop = THREE.LoopOnce;
        currentBookAnimation.timeScale = 1;
        currentBookAnimation.play(currentBookAnimation.currentTime);
        currentBookAnimation.reset();
    }
}
function reverseAnimation(escludeBook){ 
    bookAndAnimation.forEach(dictionaryElem => {
        if(dictionaryElem.key.name !==  escludeBook.name.split("_")[0]){
            const animation = dictionaryElem.value[0];
            animation.clampWhenFinished = true;
            animation.loop = THREE.LoopOnce;
            animation.timeScale = -1;
            animation.play(animation.currentTime);
        }
    });
}






function resetCamera(){
    camera.position.copy(initalCameraPosition)
    camera.lookAt(0, 0, 0);

    restoreControls();
}


function startAnimation(meshToAnimate) {
    if(meshToAnimate != null){
        console.log("mesh to animate")
        console.log(meshToAnimate)
        mixer = new THREE.AnimationMixer(meshToAnimate);
        const clipAnimation = findAnimation(meshToAnimate, myAnimationClips);

        console.log(meshToAnimate)
        if (clipAnimation != null) {
            console.log("Animazione: ");
            console.log(clipAnimation);
            const action = mixer.clipAction(clipAnimation);
            action.clampWhenFinished = true;
            action.loop = THREE.LoopOnce;
            action.fadeIn = 0.5
            action.timeScale = 1;
            action.play()
        } else console.log(meshToAnimate.name + " does not have an animation :(")
    }
}

function findAnimation(mesh, animationList) {
    for (var i = 0; i < animationList.length; i++)
        if (animationList[i].name === mesh.name.split("_")[0] + "Animation") return animationList[i];
    return null;
}



function showMacView() {
    const macViewDiv = document.querySelector(".macLayout");
    const threeDiv = document.querySelector(".three");

    canvas.style.display = "none"
    macViewDiv.style.display = "block";
    threeDiv.style.display = "none"

    setTimeout(() => {
        alert("This part of the website is currently not finished...")
    }, 1000)
    
}


function restoreControls() {
    controls.reset();
    controlsSetting();
    btnPortfolio.addEventListener("click", showPortfolio);
}

function controlsSetting() {
    controls.target.set(0, 0, 0);
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.enableRotate = true;
    controls.maxPolarAngle = Math.PI / 2.5;
    controls.maxDistance = 50;
    controls.minDistance = 20;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 3;
}

function stopControls() {
    controls.saveState();
    controls.dispose();
    controls.enableDamping = false;
    controls.autoRotate = false;
    controls.enableRotate = false;
}


function getBooksAnimation() {
    var libriAnimation = [];
    myAnimationClips.forEach(animation => {
        if (animation.name.includes("Libro")) libriAnimation.push(animation);
    });
    return libriAnimation;
}

function getBooksMesh() {
    var libriMesh = [];
    meshModel.forEach(mesh => {
        if (mesh.name.includes("Libro")) libriMesh.push(mesh);
    });
    return libriMesh;
}






function animate() {
    requestAnimationFrame(animate);
    stats.update();

    var delta = clock.getDelta();
    composer.render(delta);

    if (mixer) mixer.update(delta);

    bookAndAnimation.forEach(dictionaryElem => {
        dictionaryElem.value[1].update(delta);
    });

    if (zoomToComputer) {
        camera.fov -= 0.5;
        camera.updateProjectionMatrix();
    }

    if(!zoomToComputer && !shelfZoomed)
        controls.update();
    
    renderer.render(scene, camera);
}
















const infoBtn = document.querySelector(".infoButton");
const infoCloseBtn = document.querySelector(".infoClose");
const infoDiv = document.querySelector(".infoDiv");

infoBtn.addEventListener("click", ()=> {
    infoDiv.style.display = "flex"
})
infoCloseBtn.addEventListener("click", ()=> {
    infoDiv.style.display = "none";
})